@use 'common'

.wrapper
	--FrontPage-section-gap: 4.875rem

	@media (min-width: common.$break75)
		--FrontPage-section-gap: 10rem

.bottomContent
	display: grid
	gap: 3rem
	@media (min-width: common.$break75)
		grid-template-columns: 2fr 1fr
	&FilesOnly
		@media (min-width: common.$break75)
			grid-template-columns: 1fr
.content
	&:not(:last-child)
		margin-bottom: var(--FrontPage-section-gap)

.buttonWrapper
	text-align: center

.rightContent
	@media (min-width: common.$break75)
		border-left: 1px solid common.$color-grey-1
		margin-left: 1rem
		padding-left: 1rem

.grid
	display: flex
	flex-direction: column
	@media (min-width: common.$break75)
		display: grid
		gap: 2rem
		padding-bottom: 1.5rem
		grid-template-columns: 2fr 1fr

.topContent
	//

.middleContent
	display: grid
	gap: 4rem
	@media (min-width: common.$break75)
		grid-template-columns: repeat(auto-fill, minmax(380px, 1fr))

.column
	//

.mailingListWrapper
	margin-top: var(--FrontPage-section-gap)
	margin-bottom: var(--FrontPage-section-gap)
	&NoMarginTop
		margin-top: 0

.frontPageShortVideo
	display: grid
	gap: 1rem
	@media (min-width: common.$break75)
		grid-template-columns: 1fr 2fr
		gap: 4rem
