@use './common'

=extraImages
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: red
	display: flex
	align-items: center
	justify-content: center
	background-color: rgba(46, 46, 46, 0.69)
	font-size: 40px
	color: #fff

.wrapper
	width: 100%
	margin-top: 2rem
	margin-bottom: 2rem

.inner
	display: grid
	grid-template-columns: repeat(2, 1fr)
.imageWrapper:nth-child(7), .imageWrapper:nth-child(8)
	display: none
.imageWrapper
	position: relative
	width: 100%
	padding: 0
	padding-top: 100%
	transform: translateZ(0) // fixes border radius while zooming in Safari
	overflow: hidden
	border: none
	background-color: transparent
	cursor: pointer
	&:focus
		outline: none
	&:hover
		.image
			transform: scale(1.05)
.image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	transition: 0.2s ease-in-out transform

	&In
		object-fit: cover

.overSix
	.imageWrapper:nth-child(6):after
		counter-reset: extraImages calc(var(--Gallery-extra-images) + 2)
		content: '+' counter(extraImages)
		+extraImages

@media (min-width: common.$break30)
	.imageWrapper:nth-child(7), .imageWrapper:nth-child(8)
		display: block
	.overFour
		.imageWrapper:nth-child(4):after
			display: none
	.overEight
		.imageWrapper:nth-child(8):after
			counter-reset: extraImages var(--Gallery-extra-images)
			content: '+' counter(extraImages)
			+extraImages

.imageWrapperStadingAlone
	border-radius: common.$radius-medium
	overflow: hidden
	position: relative
	width: 100%
	aspect-ratio: 27/18
	border-radius: common.$radius-medium

.imageInfo
	font-size: 0.9rem
	color: common.$color-grey-4
	padding-top: 0.5rem
