@use 'common'

.wrapper
	display: grid
	max-width: calc(var(--ResponsiveImage-image-width) * 1px)
	position: relative

	&::after
		content: ''
		+common.violetFilterOnImage

	img
		width: 100%
		height: auto

	&.is_borderRadius
		border-radius: common.$radius-small
		overflow: hidden
