@use 'common'

.wrapper
	padding-left: 2rem
	margin: 2rem 0
	gap: 2rem
	position: relative
	&Box
		border: 1px solid var(--quote-color)
		border-radius: common.$radius-medium
		padding: 1.5rem 2rem
	&::before
		position: absolute
		top: -0.5rem
		left: 0
		content: '“'
		color: var(--quote-color)
		font-size: 7.6125em
		line-height: 1
		font-family: Helvetica,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"

.in
	padding-left: 1.5rem
	padding-top: 0.3rem

.imageWrapper
	border-radius: 50%
	flex: 0 0 auto
	width: 50px
	height: 50px
	position: relative
	overflow: hidden

.image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	object-fit: cover
	object-position: center

.meta
	display: flex
	gap: 1rem
	padding: 1rem 0

.author
	color: common.$color-black
	font-size: 1.3rem
	font-weight: 600

.text
	font-size: 1rem
	color: common.$color-black
	@media (min-width: common.$break48)
		font-size: 1.2rem

.role
	font-size: 1rem
	color: common.$color-grey-5
